<template>
  <Listbox as="div" v-model="selectedMultiple" @update:modelValue="onItemChange" v-if="multiple" multiple>
    <div class="relative">
      <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-color sm:text-sm sm:leading-6"
          :class="customClasses"
      >

        <span class="block truncate text-gray-500 text-sm">{{
            `${selectedMultiple.length > 0 ? `${selectedMultiple.length} selected ` : `Select ${title}`}`
          }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <ListboxOptions
            class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="item in items" :key="item.id" :value="item" v-slot="{ active, selected }">
            <li :class="[active ? 'bg-brand-color text-white' : 'text-gray-700', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                  item.title ?? item.name ?? ''
                }}</span>

              <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-brand-color', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <Listbox v-else as="div" v-model="selected" @update:modelValue="onItemChange">
    <div class="relative">
      <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-color sm:text-sm sm:leading-6"
          :class="customClasses"
      >
        <span class="block truncate text-gray-500 text-sm">{{
            `${Object.keys(selected).length > 0 ? `${selected['name']??selected['title']}` : `Select ${title}`}`

          }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <ListboxOptions
            class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="item in items" :key="item.id" :value="item" v-slot="{ active, selected }">
            <li :class="[active ? 'bg-brand-color text-white' : 'text-gray-700', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                  item.title ?? item.name ?? ''
                }}</span>

              <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-brand-color', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

</template>

<script setup lang="ts">
import {ref, onMounted,watch} from 'vue'
import {Listbox, ListboxButton,  ListboxOption, ListboxOptions} from '@headlessui/vue'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid'
import {multiDropDownOptionRemovedFromParent, transactionTrendDataReceived} from "~/composables/useEventBus";

let {items, title, selectedItem, customClasses, multiple} = defineProps(['items', 'title', 'selectedItem', 'multiple', 'customClasses'])
let emits = defineEmits(['onChange'])

const selected = ref({})
const selectedMultiple = ref([])

const setEventListener = () => {
  useGlobalEventLister(multiDropDownOptionRemovedFromParent(), (data:any)=>{
    //data = true
    if (data?.clearAll){
      selectedMultiple.value = []
    }else{
      selectedMultiple.value = selectedMultiple.value.filter((item:any)=>item[data.key] != data.item);
    }
    onItemChange(selectedMultiple.value);
  })
}

onMounted(() => {
  setEventListener();
  if (Array.isArray(items) && items.length > 0 && selectedItem) {
    if(multiple){
      for(const singleSelectedItem of selectedItem){
        const foundItem = items.find((item:any) => item.name == singleSelectedItem || item.title == singleSelectedItem);
        if(foundItem){
          selectedMultiple.value.push(foundItem);
        }
      }
    }
    if (items[0].title) {
      selected.value = [...items].find((item) => item.title == selectedItem);

      if(!items.find((item) => item.title == selectedItem)) {
        selected.value = [...items].find((item) => item.title == 'Other');
      }

      return;
    }

    if (items[0].name) {
      selected.value = [...items].find((item) => item.name == selectedItem);
      return;
    }

  }
})


function onItemChange(item: any) {
  emits('onChange', item)
}
</script>
